import React, { Component } from "react";
import { Button, Grid, Header, Dimmer, Loader, Image, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { rejectIfAnyNull } from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import { formatQuery } from "../controllers/utilities/string-utils";
import { getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall } from "../components/lib/ad-manage-api-lib";
import { navigate } from "../../.cache/gatsby-browser-entry";
import { getUA } from "react-device-detect";
import "../styles/bg.css";
import { AdIosaregionSwipetoslide } from "../components/react-slick/ad-iosaregion-swipetoslide";
import top from "../images/recharge/top.png";
import back from "../images/recharge/back.png";
import address from "../images/recharge/address.png";
import detal from "../images/recharge/detal.png";

import capiton from "../images/recharge/detal/hint.png";
import capitonok from "../images/recharge/detal/ok.png";

import bg from "../images/recharge/bg.png";
import box1 from "../images/recharge/box1.png";
import box3 from "../images/recharge/box3.png";
import base0 from "../images/recharge/base0.png";
import base1 from "../images/recharge/base1.png";

import { Link } from "gatsby";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import KaisakuApi from "../controllers/kaisaku-api";
import cookie from "react-cookies";

import b0 from "../images/recharge/date/0.png";
import b1 from "../images/recharge/date/1.png";
import b2 from "../images/recharge/date/2.png";
import b3 from "../images/recharge/date/3.png";
import b4 from "../images/recharge/date/4.png";
import b5 from "../images/recharge/date/5.png";
import b6 from "../images/recharge/date/6.png";
import b7 from "../images/recharge/date/7.png";
import b8 from "../images/recharge/date/8.png";
import b9 from "../images/recharge/date/9.png";
import bb from "../images/recharge/date/split.png";
import bbb from "../images/recharge/date/bb.png";
import bbbb from "../images/recharge/date/bbb.png";

import gb0 from "../images/recharge/gold/0.png";
import gb1 from "../images/recharge/gold/1.png";
import gb2 from "../images/recharge/gold/2.png";
import gb3 from "../images/recharge/gold/3.png";
import gb4 from "../images/recharge/gold/4.png";
import gb5 from "../images/recharge/gold/5.png";
import gb6 from "../images/recharge/gold/6.png";
import gb7 from "../images/recharge/gold/7.png";
import gb8 from "../images/recharge/gold/8.png";
import gb9 from "../images/recharge/gold/9.png";
import gbb from "../images/recharge/gold/split.png";
import gbbb from "../images/recharge/gold/bb.png";

import total from "../images/recharge/total.png";
import pack from "../images/recharge/pack.png";

import get from "../images/recharge/get.png";
import getok from "../images/recharge/getok.png";
import saleout from "../images/recharge/saleout.png";

import tgb0 from "../images/recharge/subitem/0.png";
import tgb1 from "../images/recharge/subitem/1.png";
import tgb2 from "../images/recharge/subitem/2.png";
import tgb3 from "../images/recharge/subitem/3.png";
import tgb4 from "../images/recharge/subitem/4.png";
import tgb5 from "../images/recharge/subitem/5.png";
import tgb6 from "../images/recharge/subitem/6.png";
import tgb7 from "../images/recharge/subitem/7.png";
import tgb8 from "../images/recharge/subitem/8.png";
import tgb9 from "../images/recharge/subitem/9.png";
import tgbt from "../images/recharge/subitem/t.png";
import tgbp from "../images/recharge/subitem/p.png";
import tgbbb from "../images/recharge/subitem/n.png";


import g2000_off from "../images/recharge/item/g2000_off.png";
import g2000_on from "../images/recharge/item/g2000_on.png";

import g5000_off from "../images/recharge/item/g5000_off.png";
import g5000_on from "../images/recharge/item/g5000_on.png";

import g10000_off from "../images/recharge/item/g10000_off.png";
import g10000_on from "../images/recharge/item/g10000_on.png";

import g20000_off from "../images/recharge/item/g20000_off.png";
import g20000_on from "../images/recharge/item/g20000_on.png";
import g20000_obj_off from "../images/recharge/item/g20000_obj_off.png";
import g20000_obj_on from "../images/recharge/item/g20000_obj_on.png";


import g40000_off from "../images/recharge/item/g40000_off.png";
import g40000_on from "../images/recharge/item/g40000_on.png";
import g40000_obj_off from "../images/recharge/item/g40000_obj_off.png";
import g40000_obj_on from "../images/recharge/item/g40000_obj_on.png";

import g80000_off from "../images/recharge/item/g80000_off.png";
import g80000_on from "../images/recharge/item/g80000_on.png";
import g80000_obj_off from "../images/recharge/item/g80000_obj_off.png";
import g80000_obj_on from "../images/recharge/item/g80000_obj_on.png";

import g150000_off from "../images/recharge/item/g150000_off.png";
import g150000_on from "../images/recharge/item/g150000_on.png";
import g150000_obj_off from "../images/recharge/item/g150000_obj_off.png";
import g150000_obj_on from "../images/recharge/item/g150000_obj_on.png";

import g250000_off from "../images/recharge/item/g250000_off.png";
import g250000_on from "../images/recharge/item/g250000_on.png";
import g250000_obj_off from "../images/recharge/item/g250000_obj_off.png";
import g250000_obj_on from "../images/recharge/item/g250000_obj_on.png";

import g400000_off from "../images/recharge/item/g400000_off.png";
import g400000_on from "../images/recharge/item/g400000_on.png";

import g600000_off from "../images/recharge/item/g600000_off.png";
import g600000_on from "../images/recharge/item/g600000_on.png";
import g600000_obj_off from "../images/recharge/item/g600000_obj_off.png";
import g600000_obj_on from "../images/recharge/item/g600000_obj_on.png";

import g900000_off from "../images/recharge/item/g900000_off.png";
import g900000_on from "../images/recharge/item/g900000_on.png";

import g1300000_off from "../images/recharge/item/g1300000_off.png";
import g1300000_on from "../images/recharge/item/g1300000_on.png";




import popup_ok from "../images/recharge/popup_ok.png";
import popuphint from "../images/recharge/popuphint.png";
import popuphint2 from "../images/recharge/popuphint2.png";



export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ready: false,
            postdata: false,
            open: false,
            open1: false,
            mode: '',
            accessToken: '',
            showTime1: '',
            showAmount: '',
            amountWidth: '',
            objId: '',
            itemInfo: {
                count: 1,
                message: ''
            },
            statusCode: 200
        };
        let { accessToken } = formatQuery(this.props.location.search);
        this.state.accessToken = accessToken;
    }

    async getTYBUserInfo() {
        if (!isValidSession(this.props) || (this.state.accessToken)) {
            let accessToken = this.state.accessToken;
            if (!accessToken) {
                accessToken = cookie.load("_accessToken");
            }
            return await KaisakuApi.getTYBUserInfo2(0, accessToken);
        } else {
            return await new KaisakuUserApi(this.props.session).getTYBUserInfo(0);
        }
    }

    async componentDidMount() {
        let data = await this.getTYBUserInfo();
        if (data.status === 200) {
            const showTime1 = dateFormat(data.data.startTime, "dd/MM/yyyy") + " - " + dateFormat(data.data.endTime, "dd/MM/yyyy");
            let showAmount = data.data.amount;
            if (showAmount > 9999999) {
                showAmount = 9999999;
            }
            let showAmount1 = showAmount.toString();
            const amountWidth = (16 + ((showAmount1.length-1) * 2)) + "vw"; 
            // const count = (7 - showAmount1.length) / 2;
            // for (let j = 0; j < count; j++) {
            //     showAmount1 = " " + showAmount1; //test
            // }

            // console.log("2222");
            // console.log(showAmount);
            let mode = '1';
            if ((data.data.userAddressInfo) && (data.data.userAddressInfo.isCn === 1)) {
                mode = '0';
            }
            if ((data.data.userAddressInfo) && (data.data.userAddressInfo.isCn === undefined)) {
                navigate(`/x-recharge-page0${this.props.location.search}`);
            } else {
                this.setState({
                    ready: true,
                    showTime1: showTime1,
                    showAmount: showAmount1,
                    amountWidth: amountWidth,
                    mode: mode,
                    data: data.data
                });
            }


        }

    }

    async postTYBObj(objId) {
        if (!isValidSession(this.props) || (this.state.accessToken)) {
            let accessToken = this.state.accessToken;
            if (!accessToken) {
                accessToken = cookie.load("_accessToken");
            }
            return await KaisakuApi.postTYBObj2(objId, accessToken);
        } else {
            return await new KaisakuUserApi(this.props.session).postTYBObj(objId);
        }
    }

    async onReceive(objId) {
        // 设置提交
        const b1 = getObjstatus(objId, this.state.data);
        if (b1 === false) return;
        this.setState({
            postdata: true,
            objId: objId
        });
        let data = await this.postTYBObj(objId);        
        if ((data.status === 200) && (!data.data.code)){
            const itemInfo = data.data.itemInfo;
            // {
            //     obj1: "g20000_obj",
            //     obj2: "g20000",
            //     count: 1,
            //     message: "9999 > 10999"
            // }
            let showAmount = data.data.amount;
            if (showAmount > 9999999) {
                showAmount = 9999999;
            }
            let showAmount1 = showAmount.toString();
            // const count = (7 - showAmount1.length) / 2;
            // for (let j = 0; j < count; j++) {
            //     showAmount1 = " " + showAmount1; //test
            // }
            const amountWidth = (16 + ((showAmount1.length-1) * 2)) + "vw"; 

            // console.log("2222");
            // console.log(showAmount);
            let mode = '1';
            if ((data.data.userAddressInfo) && (data.data.userAddressInfo.isCn === 1)) {
                mode = '0';
            }
            this.setState({
                statusCode: 200,
                showAmount: showAmount1,
                amountWidth: amountWidth,
                mode: mode,
                data: data.data,
                itemInfo,
                postdata: false
            });

        } else {
            let statusCode = 500;
            let message = ((data.data.itemInfo)?data.data.itemInfo.message:"");
            if (!message){
                message = "領取失敗";
            }else{
                message = "領取失敗: " + message;
            }
            const itemInfo = {
                message: message
            }
            this.setState({
                statusCode: statusCode,
                itemInfo,
                postdata: false,
            });
        }
        this.onOpen1();


    }

    async onReturn() {
        navigate("/confirm-purchase");
    }

    onClose() {
        this.setState({
            open: false
        });
    }

    onOpen() {
        this.setState({
            open: true
        });
    }

    onClose1() {
        this.setState({
            open1: false
        });
    }

    onOpen1() {
        this.setState({
            open1: true
        });
    }

    render() {
        return (
            <div textAlign='center' style={{
                height: "100vh",
                width: "100%",
                margin: "0 auto",
                backgroundColor: "#30221d"
            }} verticalAlign='middle'>
                <Dimmer page active={this.state.postdata}>
                    <Loader />
                </Dimmer>
                <div style={{ width: "100%" }} >
                    <Image style={{
                        width: "100%",
                    }} centered
                        src={top}
                    />

                    <div style={{ position: "absolute", top: "1%", left: "2%", width: "8%" }}><Image style={{
                        width: "100%",
                    }} centered
                        onClick={this.onReturn.bind(this)}
                        src={back}
                    /></div>

                    {(this.state.mode === "0") && (<div style={{ position: "absolute", top: "1%", right: "10%", width: "10%" }}><Image style={{
                        width: "100%",
                    }} centered
                        onClick={() => {
                            navigate(`/x-recharge-page3${this.props.location.search}`);
                        }}
                        src={address}
                    /></div>)}

                    <div style={{ position: "absolute", top: "1%", right: "2%", width: "8%" }}><Image style={{
                        width: "100%",
                    }} centered
                        onClick={this.onOpen.bind(this)}
                        src={detal}
                    /></div>

                    {(this.state.showAmount !== "") && (<div style={{ position: "absolute", top: "35vw", right: "1.5vw", height: "6vw" ,width:`${this.state.amountWidth}`}}>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[0])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[1])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[2])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[3])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[4])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[5])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage2(this.state.showAmount[6])}
                        /></div>

                    </div>)}

                    {(this.state.showTime1 !== "") && (<div style={{ position: "absolute", top: "43vw", left: "8vw", height: "5vw" }}>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[0])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[1])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[2])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[3])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[4])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[5])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[6])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[7])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[8])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[9])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[10])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[11])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[12])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[13])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[14])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[15])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[16])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[17])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[18])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[19])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[20])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[21])}
                        /></div>
                        <div style={{ float: "left", height: "100%" }}><Image style={{
                            height: "100%",
                        }} centered
                            src={returnBImage(this.state.showTime1[22])}
                        /></div>
                    </div>)}


                </div>

                <div>
                    <Image style={{
                        width: "100%",
                    }} centered
                        src={box1}
                    />
                    <div style={{ width: "80%", position: "absolute", top: "54vw", left: "11vw", height: "10vw" }}>
                        {(this.state.ready) && (<><div style={{ float: "left", height: "50%" }}>
                            <Image style={{
                                height: "100%",
                            }} centered
                                src={total}
                            />
                        </div>
                            <div style={{ float: "left", width: "28%" }}>&nbsp;</div>
                            <div style={{ float: "left", height: "50%" }}>
                                <Image style={{
                                    height: "100%",
                                }} centered
                                    src={pack}
                                />
                            </div></>)}
                    </div>
                </div>
                <div style={{
                    backgroundImage: `url(${bg})`, backgroundSize: '100% 100%',
                    minHeight: "75vh"
                }}>
                    {(this.state.ready) && (<>
                        <Image style={{
                            width: "92%",
                        }} centered
                            src={this.state.mode === "0" ? base0 : base1}
                        />
                        <div>
                            <div>
                                <div style={{ position: "absolute", top: "60.8vw", left: "45vw", height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 2000) ? g2000_on : g2000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 2000) && (
                                    <div style={{ position: "absolute", top: "61vw", left: "82vw", height: "15vw" }}
                                        onClick={() => {
                                            this.onReceive("g2000");
                                        }}>
                                        <Image style={{
                                            height: "100%",
                                        }} centered
                                            src={returnBtnIamge("g2000", this.state.data)}
                                        />
                                    </div>)}
                            </div>

                            <div>
                                <div style={{ position: "absolute", top: "77.5vw", left: "45vw", height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 5000) ? g5000_on : g5000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 5000) && (<div style={{ position: "absolute", top: "77.5vw", left: "82vw", height: "15vw" }}
                                    onClick={() => {
                                        this.onReceive("g5000");
                                    }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={returnBtnIamge("g5000", this.state.data)}
                                    />
                                </div>)}
                            </div>

                            <div>
                                <div style={{ position: "absolute", top: "94.2vw", left: "45vw", height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 10000) ? g10000_on : g10000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 10000) && (<div style={{ position: "absolute", top: "94vw", left: "82vw", height: "15vw" }}
                                    onClick={() => {
                                        this.onReceive("g10000");
                                    }}
                                >
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={returnBtnIamge("g10000", this.state.data)}
                                    />
                                </div>)}
                            </div>

                            <div>
                                {(this.state.mode === "0") && (<>
                                    <div style={{ position: "absolute", top: "112.5vw", left: "36vw", height: "14vw" }}>
                                        <Image style={{
                                            height: "100%",
                                        }} centered
                                            src={(this.state.data.amount >= 20000) ? g20000_obj_on : g20000_obj_off}
                                        />
                                    </div>
                                    {(getObjCount("g20000", this.state.data) === 0) &&
                                        (<div style={{ position: "absolute", top: "112vw", right: "47vw", height: "13vw" }}>
                                            <Image style={{
                                                height: "100%",
                                            }} centered
                                                src={saleout}
                                            />
                                        </div>)}
                                    {(getObjCount("g20000", this.state.data) > 0) &&
                                        (<div style={{ position: "absolute", top: "112vw", right: "44vw", height: "3vw" }}>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("t")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g20000", this.state.data)).toString()[0])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g20000", this.state.data)).toString()[1])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g20000", this.state.data)).toString()[2])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g20000", this.state.data)).toString()[3])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g20000", this.state.data)).toString()[4])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g20000", this.state.data)).toString()[5])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("p")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%", width:"0.5rem" }}></div>
                                        </div>)}
                                </>)}

                                <div style={{ position: "absolute", top: "111vw", left: `${this.state.mode === "0" ? "57vw" : "45vw"}`, height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 20000) ? g20000_on : g20000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 20000) && (<div style={{ position: "absolute", top: "111vw", left: "82vw", height: "15vw" }}
                                    onClick={() => {
                                        this.onReceive("g20000");
                                    }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={returnBtnIamge("g20000", this.state.data)}
                                    />
                                </div>)}
                            </div>

                            <div>
                                {(this.state.mode === "0") && (<>
                                    <div style={{ position: "absolute", top: "129.5vw", left: "36vw", height: "14vw" }}>
                                        <Image style={{
                                            height: "100%",
                                        }} centered
                                            src={(this.state.data.amount >= 40000) ? g40000_obj_on : g40000_obj_off}
                                        />
                                    </div>

                                    {(getObjCount("g40000", this.state.data) === 0) &&
                                        (<div style={{ position: "absolute", top: "128.5vw", right: "47vw", height: "13vw" }}>
                                            <Image style={{
                                                height: "100%",
                                            }} centered
                                                src={saleout}
                                            />
                                        </div>)}
                                    {(getObjCount("g40000", this.state.data) > 0) &&
                                        (<div style={{ position: "absolute", top: "128.5vw", right: "44vw", height: "3vw" }}>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("t")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g40000", this.state.data)).toString()[0])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g40000", this.state.data)).toString()[1])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g40000", this.state.data)).toString()[2])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g40000", this.state.data)).toString()[3])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g40000", this.state.data)).toString()[4])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g40000", this.state.data)).toString()[5])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("p")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%", width:"0.5rem" }}></div>
                                        </div>)}
                                </>)}
                                <div style={{ position: "absolute", top: "127.8vw", left: `${this.state.mode === "0" ? "57vw" : "45vw"}`, height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 40000) ? g40000_on : g40000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 40000) && (<div style={{ position: "absolute", top: "127.5vw", left: "82vw", height: "15vw" }}
                                    onClick={() => {
                                        this.onReceive("g40000");
                                    }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={returnBtnIamge("g40000", this.state.data)}
                                    />
                                </div>)}
                            </div>

                            <div>
                                {(this.state.mode === "0") && (<>
                                    <div style={{ position: "absolute", top: "145.5vw", left: "36vw", height: "14vw" }}>
                                        <Image style={{
                                            height: "100%",
                                        }} centered
                                            src={(this.state.data.amount >= 80000) ? g80000_obj_on : g80000_obj_off}
                                        />
                                    </div>
                                    {(getObjCount("g80000", this.state.data) === 0) &&
                                        (<div style={{ position: "absolute", top: "145.5vw", right: "47vw", height: "13vw" }}>
                                            <Image style={{
                                                height: "100%",
                                            }} centered
                                                src={saleout}
                                            />
                                        </div>)}
                                    {(getObjCount("g80000", this.state.data) > 0) &&
                                        (<div style={{ position: "absolute", top: "145.5vw", right: "44vw", height: "3vw" }}>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("t")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g80000", this.state.data)).toString()[0])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g80000", this.state.data)).toString()[1])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g80000", this.state.data)).toString()[2])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g80000", this.state.data)).toString()[3])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g80000", this.state.data)).toString()[4])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g80000", this.state.data)).toString()[5])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("p")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%", width:"0.5rem" }}></div>
                                        </div>)}
                                </>)}
                                <div style={{ position: "absolute", top: "144.5vw", left: `${this.state.mode === "0" ? "57vw" : "45vw"}`, height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 80000) ? g80000_on : g80000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 80000) && (<div style={{ position: "absolute", top: "144.5vw", left: "82vw", height: "15vw" }}
                                    onClick={() => {
                                        this.onReceive("g80000");
                                    }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={returnBtnIamge("g80000", this.state.data)}
                                    />
                                </div>)}
                            </div>


                            <div>
                                {(this.state.mode === "0") && (<>
                                    <div style={{ position: "absolute", top: "162vw", left: "36vw", height: "14vw" }}>
                                        <Image style={{
                                            height: "100%",
                                        }} centered
                                            src={(this.state.data.amount >= 150000) ? g150000_obj_on : g150000_obj_off}
                                        />
                                    </div>
                                    {(getObjCount("g150000", this.state.data) === 0) &&
                                        (<div style={{ position: "absolute", top: "162vw", right: "47vw", height: "13vw" }}>
                                            <Image style={{
                                                height: "100%",
                                            }} centered
                                                src={saleout}
                                            />
                                        </div>)}
                                    {(getObjCount("g150000", this.state.data) > 0) &&
                                        (<div style={{ position: "absolute", top: "162vw", right: "44vw", height: "3vw" }}>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("t")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g150000", this.state.data)).toString()[0])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g150000", this.state.data)).toString()[1])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g150000", this.state.data)).toString()[2])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g150000", this.state.data)).toString()[3])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g150000", this.state.data)).toString()[4])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g150000", this.state.data)).toString()[5])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("p")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%", width:"0.5rem" }}></div>
                                        </div>)}
                                </>)}
                                <div style={{ position: "absolute", top: "161.2vw", left: `${this.state.mode === "0" ? "57vw" : "45vw"}`, height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 150000) ? g150000_on : g150000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 150000) && (<div style={{ position: "absolute", top: "162vw", left: "82vw", height: "15vw" }}
                                    onClick={() => {
                                        this.onReceive("g150000");
                                    }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={returnBtnIamge("g150000", this.state.data)}
                                    />
                                </div>)}
                            </div>


                            <div>
                                {(this.state.mode === "0") && (<>
                                    <div style={{ position: "absolute", top: "179vw", left: "36vw", height: "14vw" }}>
                                        <Image style={{
                                            height: "100%",
                                        }} centered
                                            src={(this.state.data.amount >= 250000) ? g250000_obj_on : g250000_obj_off}
                                        />
                                    </div>
                                    {(getObjCount("g250000", this.state.data) === 0) &&
                                        (<div style={{ position: "absolute", top: "179vw", right: "47vw", height: "13vw" }}>
                                            <Image style={{
                                                height: "100%",
                                            }} centered
                                                src={saleout}
                                            />
                                        </div>)}
                                    {(getObjCount("g250000", this.state.data) > 0) &&
                                        (<div style={{ position: "absolute", top: "179vw", right: "44vw", height: "3vw" }}>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("t")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g250000", this.state.data)).toString()[0])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g250000", this.state.data)).toString()[1])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g250000", this.state.data)).toString()[2])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g250000", this.state.data)).toString()[3])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g250000", this.state.data)).toString()[4])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g250000", this.state.data)).toString()[5])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("p")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%", width:"0.5rem" }}></div>
                                        </div>)}
                                </>)}
                                <div style={{ position: "absolute", top: "178vw", left: `${this.state.mode === "0" ? "57vw" : "45vw"}`, height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 250000) ? g250000_on : g250000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 250000) && (<div style={{ position: "absolute", top: "178.5vw", left: "82vw", height: "15vw" }}
                                    onClick={() => {
                                        this.onReceive("g250000");
                                    }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={returnBtnIamge("g250000", this.state.data)}
                                    />
                                </div>)}
                            </div>

                            <div>
                                <div style={{ position: "absolute", top: "194.5vw", left: "45vw", height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 400000) ? g400000_on : g400000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 400000) && (<div style={{ position: "absolute", top: "195vw", left: "82vw", height: "15vw" }}
                                    onClick={() => {
                                        this.onReceive("g400000");
                                    }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={returnBtnIamge("g400000", this.state.data)}
                                    />
                                </div>)}
                            </div>


                            <div>
                                {(this.state.mode === "0") && (<>
                                    <div style={{ position: "absolute", top: "212.5vw", left: "36vw", height: "14vw" }}>
                                        <Image style={{
                                            height: "100%",
                                        }} centered
                                            src={(this.state.data.amount >= 600000) ? g600000_obj_on : g600000_obj_off}
                                        />
                                    </div>
                                    {(getObjCount("g600000", this.state.data) === 0) &&
                                        (<div style={{ position: "absolute", top: "212.5vw", right: "47vw", height: "13vw" }}>
                                            <Image style={{
                                                height: "100%",
                                            }} centered
                                                src={saleout}
                                            />
                                        </div>)}
                                    {(getObjCount("g600000", this.state.data) > 0) &&
                                        (<div style={{ position: "absolute", top: "212.5vw", right: "44vw", height: "3vw" }}>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("t")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g600000", this.state.data)).toString()[0])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g600000", this.state.data)).toString()[1])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g600000", this.state.data)).toString()[2])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g600000", this.state.data)).toString()[3])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g600000", this.state.data)).toString()[4])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage((getObjCount("g600000", this.state.data)).toString()[5])}
                                            /></div>
                                            <div style={{ float: "left", height: "100%" }}><Image style={{
                                                height: "100%",
                                            }} centered
                                                src={returnItemImage("p")}
                                            /></div>
                                            <div style={{ float: "left", height: "100%", width:"0.5rem" }}></div>
                                        </div>)}
                                </>)}

                                <div style={{ position: "absolute", top: "211.5vw", left: `${this.state.mode === "0" ? "57vw" : "45vw"}`, height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 600000) ? g600000_on : g600000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 600000) && (<div style={{ position: "absolute", top: "212vw", left: "82vw", height: "15vw" }}
                                    onClick={() => {
                                        this.onReceive("g600000");
                                    }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={returnBtnIamge("g600000", this.state.data)}
                                    />
                                </div>)}
                            </div>


                            <div>
                                <div style={{ position: "absolute", top: "228vw", left: "45vw", height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 900000) ? g900000_on : g900000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 900000) && (<div style={{ position: "absolute", top: "228.5vw", left: "82vw", height: "15vw" }}
                                    onClick={() => {
                                        this.onReceive("g900000");
                                    }}
                                >
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={returnBtnIamge("g900000", this.state.data)}
                                    />
                                </div>)}
                            </div>

                            <div>
                                <div style={{ position: "absolute", top: "245vw", left: "45vw", height: "15vw" }}>
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={(this.state.data.amount >= 1300000) ? g1300000_on : g1300000_off}
                                    />
                                </div>
                                {(this.state.data.amount >= 1300000) && (<div style={{ position: "absolute", top: "246vw", left: "82vw", height: "15vw" }}
                                    onClick={() => {
                                        this.onReceive("g1300000");
                                    }}
                                >
                                    <Image style={{
                                        height: "100%",
                                    }} centered
                                        src={returnBtnIamge("g1300000", this.state.data)}
                                    />
                                </div>)}
                            </div>

                        </div>


                    </>)}
                </div>
                <div>
                    <Image style={{
                        width: "100%",
                    }} centered
                        src={box3}
                    />
                </div>
                <Modal
                    style={{ width: '96%', maxWidth: 450, backgroundColor: "#252722" }}
                    open={this.state.open}
                >
                    <div style={{ width: "100%", marginBottom: "1%" }}>
                        <Image style={{
                            width: "100%",
                        }} centered
                            src={capiton}
                        />
                    </div>

                    <div style={{ width: "100%" }}><Image style={{
                        width: "100%",
                    }} centered
                        src={capitonok}
                        onClick={this.onClose.bind(this)}
                    /></div>
                </Modal>

                <Modal
                    style={{ width: '96%', maxWidth: 450, backgroundColor: "#252722" }}
                    open={this.state.open1}
                >
                    <div style={{
                        width: "100%",
                        backgroundImage: `url(${(this.state.statusCode === 200) ? popuphint : popuphint2})`, backgroundSize: '100% 100%', height: "80%", fontsiz: "1.2rem", fontWeight: "bold", color: "#FFDFBB"
                    }}>
                        <div style={{ width: "100%", height: "70vw" }}>
                            <div style={{ width: "100%", paddingTop: "20vw" }}>
                                {(this.state.itemInfo.count > 1) && (<><div style={{ float: "left", width: "50%", paddingLeft: "20vw" }}><Image style={{
                                    width: "30vw",
                                }}
                                    src={getObjIdLogo1(this.state.objId)}
                                /></div>
                                    <div style={{ float: "right", width: "50%", paddingRight: "20vw" }}><Image style={{
                                        width: "30vw",
                                    }}
                                        src={getObjIdLogo2(this.state.objId)}
                                    /></div></>)}
                                {(this.state.itemInfo.count === 1) && (<>
                                    <div style={{ float: "right", width: "100%" }}><Image style={{
                                        width: "30vw",
                                    }} centered
                                        src={getObjIdLogo2(this.state.objId)}
                                    /></div></>)}
                            </div>


                            <div style={{ width: "100%", fontSize: `${(this.state.statusCode === 200) ? "1.2rem" : "1.6rem"}`,marginTop:`${(this.state.statusCode === 200) ? "0" : "-5rem"}`, fontWeight: "bold", color: "#FFDFBB", textAlign: "center", paddingTop: "34vw" }}>
                                {this.state.itemInfo.message}
                            </div>
                        </div>


                    </div>

                    <div style={{ width: "100%" }}><Image style={{
                        width: "100%",
                    }} centered
                        src={popup_ok}
                        onClick={this.onClose1.bind(this)}
                    /></div>
                </Modal>
            </div>
        );
    }
});

function getObjIdLogo1(objId){
    console.log("logo1");
    console.log(objId);
    switch (objId) {
        case "g20000":
            return g20000_obj_on;
        case "g40000":
            return g40000_obj_on;
        case "g80000":
            return g80000_obj_on;
        case "g150000":
            return g150000_obj_on;
        case "g250000":
            return g250000_obj_on;
        case "g600000":
            return g600000_obj_on;
    }
}

function getObjIdLogo2(objId){
    console.log("logo2");
    console.log(objId);
    switch (objId) {
        case "g2000":
            return g2000_on;
        case "g5000":
            return g5000_on;
        case "g10000":
            return g10000_on;
        case "g20000":
            return g20000_on;
        case "g40000":
            return g40000_on;
        case "g80000":
            return g80000_on;
        case "g150000":
            return g150000_on;
        case "g250000":
            return g250000_on;
        case "g400000":
            return g400000_on;
        case "g600000":
            return g600000_on;
        case "g900000":
            return g900000_on;
        case "g1300000":
            return g1300000_on;
    }
}

function getObjCount(oId, data) {
    const data1 = data.ActivityInfo;
    for (let j = 0; j < data1.length; j++) {
        const item = data1[j];
        if (item.objId === oId) {
            return (item.maxCount - item.saleCount)
        }
    }
    return 0;
}

function returnBtnIamge(oId, data) {
    if (getObjstatus(oId, data) === true) {
        return get;
    } else {
        return getok;
    }
}

function getObjstatus(oId, data) {
    const data1 = data.userActivityInfo;
    if (data1) {
        for (let j = 0; j < data1.length; j++) {
            const item = data1[j];
            if (item.objId === oId) {
                return false;
            }
        }
    }
    return true;
}


function returnItemImage(code) {
    switch (code) {
        case "0":
            return tgb0;
        case "1":
            return tgb1;
        case "2":
            return tgb2;
        case "3":
            return tgb3;
        case "4":
            return tgb4;
        case "5":
            return tgb5;
        case "6":
            return tgb6;
        case "7":
            return tgb7;
        case "8":
            return tgb8;
        case "9":
            return tgb9;
        case "t":
            return tgbt;
        case "p":
            return tgbp;
    }
}


function returnBImage2(code) {
    switch (code) {
        case "0":
            return gb0;
        case "1":
            return gb1;
        case "2":
            return gb2;
        case "3":
            return gb3;
        case "4":
            return gb4;
        case "5":
            return gb5;
        case "6":
            return gb6;
        case "7":
            return gb7;
        case "8":
            return gb8;
        case "9":
            return gb9;
        case "-":
            return gbb;
        default:
            return gbbb;
    }
}

function returnBImage(code) {

    switch (code) {
        case "0":
            return b0;
        case "1":
            return b1;
        case "2":
            return b2;
        case "3":
            return b3;
        case "4":
            return b4;
        case "5":
            return b5;
        case "6":
            return b6;
        case "7":
            return b7;
        case "8":
            return b8;
        case "9":
            return b9;
        case "/":
            return bb;
        case "-":
            return bbb;
        default:
            return bbbb;
    }
}

function dateFormat(time, fmt) { // author: meizz
    const date = new Date(time);
    // console.log(date);
    let o = {
        "M+": date.getMonth() + 1, // 月份
        "d+": date.getDate(), // 日
        "h+": date.getHours(), // 小时
        "m+": date.getMinutes(), // 分
        "s+": date.getSeconds(), // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        "S": date.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt))
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
    for (let k in o)
        if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
    return fmt;
}


function isValidSession(props) {
    if (!props) return false;
    return !!props.session && !!props.session.accessToken;
}